import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import { Layout, Header, Sidebar } from 'components';
import kebabCase from 'lodash/kebabCase';
import styled from 'styled-components';

const MainWrapper = styled.main`
  display: flex;
  width: 100%;
  min-height: calc(100% - 4rem);
  flex-direction: row;

  .iframe {
    width: calc(100% - 320px);
    border: none;
    margin: 0;
    background-color: #a7a7a7;
  }
`;

const Brand = ({
  data: {
    allMarkdownRemark: { edges },
  },
}) => {
  const { brand } = edges[0].node.frontmatter;
  const links = edges.map(x => ({
    title: x.node.frontmatter.title,
    url: `${kebabCase(brand)}${x.node.fields.slug}`,
  }));

  return (
    <Layout>
      <Header brandLogo={brand} />
      <MainWrapper>
        <Sidebar heading="Select a campaign below:" links={links} />
        <iframe className="iframe" title="banners" />
      </MainWrapper>
    </Layout>
  );
};

export default Brand;

Brand.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      totalCount: PropTypes.number,
      edges: PropTypes.array.isRequired,
    }),
  }).isRequired,
};

export const BrandPageQuery = graphql`
  query BrandPageQuery($brand: String!) {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { brand: { eq: $brand } } }
    ) {
      totalCount
      edges {
        node {
          frontmatter {
            title
            brand
          }
          fields {
            slug
          }
        }
      }
    }
  }
`;
